<template>
    <form @submit.prevent="submitForm" v-if="!isAuthenticated" data-e2e="loginForm">
        <v-title title="Login"></v-title>

        <div class="row">
            <mercur-card class="mb-4 login-form">
                <mercur-input v-model="form.username" id="username" data-e2e="loginEmail" type="text" autofocus :class="getValidationClass($v, 'username')">
                    Email
                    <template #note>
                        <span class="form-error" v-if="!$v.form.username.required">Username is required</span>
                    </template>
                </mercur-input>

                <mercur-input v-model="form.password" id="password" data-e2e="loginPassword" type="password" :class="getValidationClass($v, 'password')">
                    Password
                    <template #note>
                        <span class="form-error" v-if="!$v.form.password.required">Password is required</span>
                    </template>
                </mercur-input>

                <div class="row mt-3">
                    <div class="col-6">
                        <mercur-button data-e2e="loginSubmit" class="btn btn-primary" type="submit" :disabled="loading">Log in</mercur-button>
                    </div>
                    <div class="col-6 text-right mt-3">
                        <a @click.prevent="isDialogOpen = true">Forgot password</a>
                    </div>
                </div>
            </mercur-card>
            <mercur-dialog :is-open.sync="isDialogOpen">
                <form @submit.prevent="requestNewPassword" class="mt-3">
                    <mercur-input v-model="username" id="usernamePasswordRecovery" type="email" required>
                        Type your username/email
                    </mercur-input>
                    <div class="text-right mt-3">
                        <mercur-button class="btn" :disabled="isResettingPassword" @click.native.prevent="isDialogOpen = false">Close</mercur-button>
                        <mercur-button type="submit" class="btn btn-primary" :disabled="isResettingPassword">Request new password</mercur-button>
                    </div>
                </form>
            </mercur-dialog>
        </div>
    </form>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import CONFIG from '@root/config'
import { required } from 'vuelidate/lib/validators'

import FormMixin from '@/mixins/Form'

export default {
    name: 'LoginView',
    mixins: [FormMixin],
    data () {
        return {
            form: {},
            isDialogOpen: false,
            username: '',
            isResettingPassword: false,
        }
    },
    validations: {
        form: {
            username: {
                required,
            },
            password: {
                required,
            },
        },
    },
    computed: {
        ...mapGetters('auth', ['isAuthenticated']),
        action () {
            return CONFIG.API.ROUTES.LOGIN
        },
        isLoading () {
            if (this.loading || this.$store.state.auth.isLoading) {
                return true
            }
            return false
        },
    },

    methods: {
        ...mapActions('auth', [ 'login', 'recoverPassword' ]),
        loginUser (data) {
            this.login(data.jwt).then(path => {
                this.$router.push(path || '/')
            })
        },
        submitForm () {
            this.submit(this.action, this.form).then(data => {
                this.loginUser(data)
            })
        },
        requestNewPassword () {
            this.isResettingPassword = true

            this.recoverPassword({ username: this.username }).then(() => {
                this.$root.$emit('notification:global', {
                    message: 'Password reset email was sent',
                    type: 'success',
                })
                this.isResettingPassword = false
                this.isDialogOpen = false
                this.username = ''
            }).catch(() => {
                this.$root.$emit('notification:global', {
                    message: 'Something went wrong.',
                    type: 'error',
                })
            }).finally(() => {
                this.isResettingPassword = false
            })
        },
    },
}
</script>

<style scoped>
    .login-form {
        margin: auto;
        margin-top: 30vh;
        min-width: 500px;
    }
</style>
